import React from 'react';

import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

class StoryPage extends React.Component {
    render() {
        const styles = {
            a: {
                color: 'black',
                textDecoration: 'none',
                fontSize: '1.5em',
            },
            appBar: {
                backgroundColor: 'white',
            },
            banner: {
                backgroundImage: 'url(/img3.jpg)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                minHeight: '100vh',
                paddingTop: '10%',
                paddingBottom: '10%',
                filter: 'drop-shadow(0px 0.25em 0.5em black)',
            },
            banner2: {
                backgroundImage: 'url(/img5.jpg)',
                // backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                minHeight: '50vh',
                paddingTop: '10%',
                paddingBottom: '10%',
                filter: 'drop-shadow(0 0.25em 0.5em black)',
            },
            container: {
                backgroundColor: '#fafafa',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                paddingBottom: '2%',
                minHeight: '100vh',
            },
            marginLeft: {
                marginLeft: 'auto',
                fontSize: '0.66em',
            },
            navBarImg: {
                height: '3rem',
                padding: '.5rem',
            },
            hr: {
                borderStyle: 'solid',
                borderTop: '0.1em solid black',
                width: '5em',
                marginBottom: '2em'
            },
            h2: {
                marginBottom: '0.1em',
                fontWeight: '400',
                fontSize: '48px'
            },
            p1: {
                fontWeight: '300',
                letterSpacing: '0.04em',
                fontSize: '24px',
                lineHeight: '28px',
            },
          toolBar: {
            minHeight: 'auto',
          },
        };

        const images = [
            {
                img: '/img1.jpg',
                title: 'img1',
                cols: 2,
            },
            {
                img: '/img4.jpg',
                title: 'img4',
                cols: 1,
            },
            {
                img: '/img3.jpg',
                title: 'img3',
                cols: 3,
            },
            {
                img: '/img2.jpg',
                title: 'img2',
                cols: 1,
            },
            {
                img: '/img5.jpg',
                title: 'img5',
                cols: 2,
            },
            {
                img: '/img7.jpg',
                title: 'img7',
                cols: 2,
            },
            {
                img: '/img6.jpg',
                title: 'img6',
                cols: 1,
            },
        ];

        return (
            <div className="StoryPage">
                <AppBar position="fixed" style={styles.appBar}>
                    <Toolbar style={styles.toolBar}>
                        <a href="#Top">
                            <img src="/emr-logo.jpg" style={styles.navBarImg} alt="EMR logo" />
                        </a>
                        <div style={styles.marginLeft}>
                            <a href="#Story" style={styles.a}>
                                Story&nbsp;&nbsp;&nbsp;&nbsp;
                            </a>
                            <a href="#Vision" style={styles.a}>
                                Vision&nbsp;&nbsp;&nbsp;&nbsp;
                            </a>
                            <a href="#Gallery" style={styles.a}>
                                Gallery&nbsp;&nbsp;&nbsp;&nbsp;
                            </a>
                            <a href="#Contact" style={styles.a}>
                                Contact&nbsp;&nbsp;&nbsp;&nbsp;
                            </a>
                        </div>
                    </Toolbar>
                </AppBar>
                <Fade in={true} timeout={{enter: 600}}>
                    <Grid container justify="center" style={styles.container}>
                        <Grid item xs={12} id="Top">
                            <Grid container style={styles.banner} justify="center" alignItems="center">
                                <Grid item xs={12} align="center">
                                    <img src="/emr-text.jpg" width="300px" alt="EMR text logo" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} id="Story">
                            <Grid container>
                                <Grid item xs={12} style={{padding: '20%', backgroundColor: '#f2f2f2'}}>
                                    <h2 style={styles.h2}>
                                        Our Story
                                    </h2>
                                    <hr style={styles.hr} />
                                    <p align="left" style={styles.p1}>
                                        At Ea.Maa.Rilo we like to say <i>C'est un Sentiment</i> which means it's a feeling. That feeling of an old friend - inviting, warm and familiar.
                                    </p>
                                    <p align="left" style={styles.p1}>
                                        Our designs are classic, simple and crafted to compliment the rest of your daily armour so you can look as good as you feel to take on whatever comes today.
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} id="Vision">
                            <Grid container style={styles.banner2}>
                                <Grid item md={6}></Grid>
                                <Grid item xs={12} md={6} style={{padding: '5%'}} align="left">
                                    <Card style={{padding: '2em', backgroundColor: '#f2f2f2'}}>
                                        <h2 style={{
                                            marginBottom: '0.1em',
                                            fontWeight: '400',
                                            fontSize: '36px'
                                        }}>
                                            Our Vision
                                        </h2>
                                        <hr style={{
                                            borderStyle: 'solid',
                                            borderTop: '0.05em solid black',
                                            width: '5em',
                                            marginBottom: '2em',
                                            marginInlineStart: 'inherit',
                                        }} />
                                        <p style={{
                                            fontWeight: '300',
                                            letterSpacing: '0.04em',
                                            fontSize: '18px',
                                            lineHeight: '24px',
                                        }}>
                                            Our project aims to use the latest innovations in creating the very best quality garment for wear after wear, all with the view of making timeless pieces that are wonderfully soft. That's why we believe the better the quality the longer lasting and more comfortable the fit.
                                        </p>
                                        <p style={{
                                            fontWeight: '300',
                                            letterSpacing: '0.04em',
                                            fontSize: '18px',
                                            lineHeight: '24px',
                                        }}>
                                            We embrace sustainable production processes for all our products. Using sustainable and breathable fabrics is our promise through the use of eco-friendly fibres such as our hemp cotton, organic cotton and ringspun cotton. We aim to produce stronger, longer strands that are extremely durable, free from rough textures such as that from standard raw cotton.
                                        </p>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} id="Gallery" align="left" style={{padding: '5% 5% 3% 5%'}}>
                            <h2 style={{
                                marginBottom: '0.1em',
                                fontWeight: '400',
                                fontSize: '36px'
                            }}>
                                Gallery
                            </h2>
                            <hr style={{
                                borderStyle: 'solid',
                                borderTop: '0.05em solid black',
                                width: '5em',
                                marginBottom: '2em',
                                marginInlineStart: 'inherit',
                            }} />
                            <GridList cellHeight={400} cols={3}>
                                {images.map(tile => (
                                    <GridListTile key={tile.img} cols={tile.cols || 1}>
                                        <img src={tile.img} alt={tile.title} />
                                    </GridListTile>
                                ))}
                            </GridList>
                        </Grid>
                        <Grid item xs={12} id="Contact">
                            <h2 style={{
                                marginBottom: '0.1em',
                                fontWeight: '400',
                                fontSize: '36px'
                            }}>
                                Contact
                            </h2>
                            <hr style={{
                                borderStyle: 'solid',
                                borderTop: '0.05em solid black',
                                width: '5em',
                                marginBottom: '2em',
                            }} />
                            <p style={{
                                fontWeight: '300',
                                letterSpacing: '0.04em',
                                fontSize: '18px',
                                lineHeight: '24px',
                            }}>
                                <a href="mailto:info@maarilo.co.uk" style={{color: 'black'}}>Reach out</a> for more info about our latest collection.
                            </p>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop: '10em'}}>
                            <span style={{fontSize: '12px', color: '#808080'}}>
                                Copyright © {(new Date()).getFullYear()} EA.MAA.RILO. All rights reserved.
                            </span>
                        </Grid>
                    </Grid>
                </Fade>
            </div>
        );
    }
}

export default StoryPage;
