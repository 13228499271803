import React from 'react';

function GalleryPage() {
    return (
        <div className="GalleryPage">
            <h1>Gallery</h1>
        </div>
    );
}

export default GalleryPage;
