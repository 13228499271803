import React from 'react';

function VisionPage() {
    return (
        <div className="VisionPage">
            <h1>Our Vision</h1>
        </div>
    );
}

export default VisionPage;
