import React from 'react';
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';
import './App.css';

import StoryPage from './components/StoryPage.js';
import VisionPage from './components/VisionPage.js';
import GalleryPage from './components/GalleryPage.js';

function App() {
  return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/" component={Story} />
            <Route exact path="/vision" component={Vision} />
            <Route exact path="/gallery" component={Gallery} />
            <Redirect to="/" />
          </Switch>
        </Router>
      </div>
  );
}

function Story() {
  return (
      <StoryPage />
  );
}

function Vision() {
  return (
      <VisionPage />
  );
}

function Gallery() {
  return (
      <GalleryPage />
  );
}

export default App;
